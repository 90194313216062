import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Nem található" />
      <h1>Nem található :(</h1>
      <p>Sajnos ezen a linken nem található semmi.</p>
    </Layout>
  )
}
